import React from 'react'; 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import './App.css'; 
import Enter from './components/Enter'; 
import Nav from './components/Nav'; 
import Background from './components/Background'; 
import Lab from './components/Lab'; 
import Voice from './components/Voice';
import Aboutpage from './components/Aboutpage';
import { ParallaxProvider } from 'react-scroll-parallax'; 
import Workpage from './components/Workpage'; 
import Contactpage from './components/Contactpage';


function App() {
  return (
    <Router basename='/'> 
      
      <ParallaxProvider>
      <Routes> 
        <Route exact path='/' element={<Enter/>} /> 
        <Route path='/about' element={<Aboutpage/>} />  
        <Route path='/projets' element={<Workpage/>} /> 
        <Route path='/contact' element={<Contactpage/>} />
        <Route path='/lab' element={<Lab/>} />
        <Route path='/lab/voice' element={<Voice/>} />
      </Routes>
      </ParallaxProvider>
    

    </Router>
  );
}

export default App;
