import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import Nav from './Nav'; 
import Top from './Top'; 
import Footer from './Footer';
import Comps from './Comps';
import Description from './Description';


function Aboutpage() {
  return (
    <div>
        <Nav />
        <Description/>
        <Comps/> 
        <Footer/>
    </div>
  )
}

export default Aboutpage