import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import Nav from './Nav'; 
import Contact from './Contact';
import Footer from './Footer';


export default function Contactpage() {
  return (
    <div>
        <Nav />
        <Contact/>
        <Footer/>
    </div>
  )
}
