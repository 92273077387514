import React from 'react'
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css' 
import Nav from './Nav'; 
import { useState } from 'react';
import Background from './Background'; 
import Top from './Top'; 
import Description from './Description'; 
import Comps from './Comps';
import Projects from './Projects';
import Contact from './Contact'; 
import Footer from './Footer';

function Enter() { 

    const [toggled , setToggled] = useState('hamburger')

    const toggleNav = () => { 
        if (toggled == 'hamburger') {
            setToggled('hamburger is-active');
        } else {
            setToggled('hamburger'); 
        }
        
         
    }

  return (
    <div>
        <Nav /> 
        <Background /> 
        <Top /> 
        {console.log}
        <Description/>
        <Comps /> 
        <Projects /> 
        <Contact /> 
        <Footer /> 

        
    </div>
  )
}

export default Enter