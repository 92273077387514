import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import './proj.css'; 
import { Parallax } from 'react-scroll-parallax'; 
import clonebook from './img/clonebook.png'; 
import clonebookmobile from './img/clonebookmobile.png'; 
import lce from './img/lce.png'; 
import lce2 from './img/lce2.png'; 
import weather from './img/weather.png'; 
import login from './img/login.PNG'

function Projects() {
  return (
    <div className='proj'>
        <div className='projtitle'>Projets</div>
        <div className='projet'>
            <div className='imagediv'>
                <div className='mainimg'><img src={clonebook} alt="" /></div> 
                <Parallax className='mobileimg' translateY={[20, -20]}>
                    <img src={clonebookmobile} alt="" />
                </Parallax>
            </div>
            <div className='textdiv'>
                <div className='texttitle'>Clonebook</div>
                <div className='projtext'><div>Un réseau social complet, léger et modulable basé sur React et Express.js, avec une base de données sous MongoDB.</div> 
                <div>Cette app est destinée à des entreprises ou des associations afin de garder un certain contrôle sur le côté serveur.</div></div> 
                <a href="https://infinite-woodland-47701.herokuapp.com/">
                    <div className='visit'>Visiter le site</div>
                </a>
            </div>
           
        </div>

        <div className='projet'>
            <div className='textdiv'>
                <div className='texttitle'>LCE</div>
                <div className='projtext'><div>Un site portfolio pour une entreprise de maîtrise d'oeuvre.</div> 
                <div>Pour une structure de ce type, un design moderne et épuré était essentiel.</div></div> 
                <a href="https://lcengineering.fr/">
                    <div className='visit'>Visiter le site</div>
                </a>
            </div> 
            <div className='imagediv'>
                <div className='mainimg'><img src={lce2} alt="" /></div> 
                <Parallax className='secondimg'  translateY={[20, -20]}>
                    <img src={lce} alt="" />
                </Parallax>
            </div>
        </div> 

        <div className='projet'>
            <div className='imagediv'>
                <div className='mainimg'><img src={weather} alt="" /></div> 
                
            </div>
            <div className='textdiv'>
                <div className='texttitle'>Weather</div>
                <div className='projtext'><div>Une app météo  pour fond d'écran basée sur OpenWeatherAPI</div> 
                </div> 
                <a href="https://dorianche.github.io/weather-app/">
                    <div className='visit'>Visiter le site</div>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Projects