import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import { Parallax } from 'react-scroll-parallax'; 

function Comps() {
  return (
    <div className='comps'>
        <div className='comphead'>Compétences</div> 
        <div className='compcontainer'>
            <Parallax translateX={[-20, 10]}>
                <div className='langages'>
                    <div className='comptitle'>Langages</div>
                    <div className='grid'>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg" alt="" /></div>
                            <div className='compname'>Javacript</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="" /></div>
                            <div className='compname'>React</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="" /></div>
                            <div className='compname'>Node.js</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg" alt="" /></div>
                            <div className='compname'>Typescript</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/9/96/Sass_Logo_Color.svg" alt="" /></div>
                            <div className='compname'>SASS</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'>&lt;%= EJS %&gt;</div>
                            <div className='compname'>EJS</div>
                        </div>
                
                    </div>
                </div>
            </Parallax>
            <Parallax translateX={[20, -10]}>
                <div className='technos'>
                
                    <div className='grid'>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png" alt="" /></div>
                            <div className='compname'>Express</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/9/93/MongoDB_Logo.svg" alt="" /></div>
                            <div className='compname'>MongoDB</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" alt="" /></div>
                            <div className='compname'>AWS</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg" alt="" /></div>
                            <div className='compname'>Redux</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Heroku_logo.svg" alt="" /></div>
                            <div className='compname'>Heroku</div>
                        </div>
                        <div className='card'>
                            <div className='complogo'><img src="https://upload.wikimedia.org/wikipedia/commons/3/37/Firebase_Logo.svg" alt="" /></div>
                            <div className='compname'>Firebase</div>
                        </div>
                
                    </div>
                    <div className='comptitle'>Technologies</div>
                </div>
            </Parallax>
        </div>
    </div>
  )
}

export default Comps