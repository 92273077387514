import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import Nav from './Nav'; 
import Top from './Top'; 
import Footer from './Footer';
import Projects from './Projects';

function Workpage() {
  return (
    <div>
        <Nav />
        <Projects/>
        <Footer/>
    </div>
  )
}

export default Workpage