import React from 'react'; 
import {BrowserRouter as Router , Switch , Outlet, Link , Route , Routes, HashRouter} from 'react-router-dom'; 
import './lab.css'; 
import {useState , useRef} from 'react'; 




function Lab() {
  return (
    <div>
        <h1>Laboratory</h1>
        <div>This place is made to test stuff !</div> 

        <div>
            <h2>Voice Recognition</h2> 
            <Link to='/lab/voice'>
                    <div className='visit'>Check it out</div>
            </Link>
        </div>
    </div>
  )
}

export default Lab