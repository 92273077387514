import React from 'react' 
import {BrowserRouter as Router , Switch , Outlet, Link, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 

function Footer() {
  return (
    <div className='footer'>
        <div className='panel'>
            <div className='navigation'>
            
                <Link style={{textDecoration: 'none'}} to='/' >
                    <div className='sectiontitle'>Dorian Chénard</div>
                </Link>
            
            
                <Link to='/about'>
                    <div className='normal'>À propos</div>
                </Link> 


            <Link to='/projets'>
                <div className='normal'>Projets</div>
            </Link>
            <Link to='/contact'>
                <div className='normal'>Contact</div>
            </Link>
            </div>
            <div className='navigation'>
            <div className='sectiontitle'>Coordonnées</div>
            <div className='normal'>dorian.chenard(at)gmail.com</div>
            <div className='normal'>06 89 22 98 56</div>
            
            </div>
            <div className='navigation'> 
            <div className='sectiontitle'>Connect</div>
            <div className='normal'>Github</div> 
            <div className='normal'>Malt</div>
            <div className='normal'>Linkedin</div>
            
            </div>
        </div> 

        <div className='copyright'>
            <div>Copyright Dorian Chénard</div> 
            <div>Tous droits réservés</div>
        </div>
    </div>
  )
}

export default Footer