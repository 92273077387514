import React from 'react'; 
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import { Parallax } from 'react-scroll-parallax'; 
 


function Description() {
  return (
    <div className='desc'>
        <div className='desctitle'>À propos</div> 
        <div className='descblock'>
            <div className='slogan'>Construire des solutions à travers le design et la technologie.</div>
            <div className='desctext'>Bienvenue ! Je crée des applications et sites aux ergonomies intuitives et pratiques. Je peux intervenir tant sur l'aspect client que du côté serveur et base de données. </div>
            <div className='desctext'>J'utilise majoritairement le Javascript, React et Node.js afin de proposer des applications modulables et durables, issues des technologies les plus récentes. </div>
        </div> 
        <Parallax translateY={[30, -30]}>
            <div className='parallaxblock'></div>
        </Parallax>
    </div>
  )
}

export default Description