import React from 'react'; 
import {BrowserRouter as Router , Switch , Outlet, Link , Route , Routes, HashRouter} from 'react-router-dom'; 
import './contact.css'; 
import {useState , useRef} from 'react'; 
import emailjs from '@emailjs/browser'; 



function Contact() { 

    const [name , setName] = useState(''); 
    const [email , setEmail] = useState(''); 
    const [text , setText] = useState(''); 
    const [isShown, setIsShown] = useState(false);
    const form = useRef(); 

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ijg3ig4', 'template_2tuxo8r', form.current, '5LprgL7qs56PECepk')
          .then((result) => {
              console.log(result.text);
              alert('Message envoyé !'); 
              window.location.reload();
          }, (error) => {
              console.log(error.text);
          });
      };

    const handleClick = event => {
        // 👇️ toggle shown state
        setIsShown(current => !current);
    
        // 👇️ or simply set it to true
        // setIsShown(true);
      };

  return (
    <div className='contactcomp'>
        <div onClick={handleClick} className='contactbox'>
            <div className='contacttitle'>Me Contacter</div>
            <span className="material-symbols-outlined">history_edu</span>
            <div className='contacttext'>N'hésitez pas à m'écrire, que ce soit pour une simple question ou pour discuter d'un projet ! Je me ferai un plaisir de vous répondre dans les plus brefs délais.</div>
        </div>

        {isShown && 
        
            <form ref={form} onSubmit={sendEmail} className='formbox' action="">
                <label htmlFor="Message">Votre message</label> 
                <input className='simpleform'
                    type="text" 
                    name="name" 
                    id="name" 
                    placeholder='Votre nom'
                    required={true}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    /> 

                <input className='simpleform'
                    type="email" 
                    name="email" 
                    id="email" 
                    placeholder='Votre email'
                    required={true}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    /> 

                <input className='messagebox' 
                    type="textbox" 
                    name="message" 
                    id="text" 
                    placeholder='Votre message'
                    required={true}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    />

                <input className="postbutton" type="submit" value='Envoyer' />
                    

            </form>

         
}
    </div>
  )
}

export default Contact