import React from 'react'; 
import { useState } from 'react';
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 

function Top() {
  return (
    <div className='top'>
      <div className='title'>
        <h1>Dorian Chénard</h1>
        <div className='subtitle'>DEVELOPPEUR FULLSTACK JAVASCRIPT</div>
      </div>
    </div>
  )
}

export default Top