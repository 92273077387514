import React, { useEffect } from 'react' 
import { useState } from 'react';
import {BrowserRouter as Router , Switch , Link, Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import '../App.css'; 
import Background from './Background';
import Tabbutton from './Tabbutton';


function Nav() { 

    const [toggled , setToggled] = useState('link'); 
    const [ham , setHam] = useState('hamburger icon'); 
    const [group , setGroup] = useState('');  
    const [header , setHeader] = useState('name'); 
    const [hambutton , setHambutton] = useState('');

    const scrollPos = (event) => {
        if (window.scrollY>400) {
            setHeader('namedark'); 
            setHambutton('hamdark');
        } else {
            setHeader('name');
            setHambutton('hamlight');
        } 
    } 

    useEffect(() => {
         window.addEventListener('scroll' , scrollPos);

         return () => {
            window.removeEventListener('scroll' , scrollPos); 
         }
    }, [])

    const toggleList = () => { 
        if (toggled === 'link') {
            setToggled('link responsive');
        } else {
            setToggled('link'); 
        } 
    } 

    const toggleNav = () => { 
        if (ham == `hamburger icon`) {
            setHam('hamburger is-active icon');
            setGroup('grouplink'); 
        } else {
            setHam('hamburger icon'); 
            setGroup(''); 
        }  
    } 


  return (
    <div className='nav'>
        <Link to='/'>
            <div className='logo'>
            
            <svg className='draw' version="1.1" viewBox="0 0 89.191 52.539" xmlns="http://www.w3.org/2000/svg">
            <defs>
            <clipPath id="a">
            <path class="svg-elem-2" d="m-65.083 12.53h66.894v-39.404h-66.894z" stroke-width="2"/>
            </clipPath>
            </defs>
            <g transform="matrix(1.3333 0 0 -1.3333 0 52.539)" fill="none" stroke-width="2">
            <g transform="translate(54.583 26.874)" clip-path="url(#a)" stroke="#bdd0f5">
            <path class="svg-elem-3" d="m0 0c-0.816 6.097-5.473 9.457-12.529 9.457-10.753 0-18.146-8.641-18.146-20.354 0-8.208 4.993-13.681 13.298-13.681 6.192 0 11.616 2.064 15.121 9.121" fill="none" stroke="#bdd0f5" stroke-miterlimit="10" stroke-width="2"/>
            </g>
            <g transform="translate(2.5312 2.9229)" stroke="#236576">
            <path class="svg-elem-4" d="m0 0h11.041c11.713 0 19.97 6.96 19.97 18.578 0 8.208-5.425 14.112-14.066 14.112h-10.08z" fill="none" stroke="#236576" stroke-miterlimit="10" stroke-width="2"/>
            </g>
            </g>
            </svg>
                <div className={header}>
                    <p className='do'>Dorian Chénard</p>
                    <p className='job'>Développeur Fullstack</p>
                </div>
                </div>
        </Link>
        <div className='hidable'>
            <div className={group}>
                <Link to='/about'>
                    <div className={toggled}>À propos</div>
                </Link>
                <Link to='/projets'>
                    <div className={toggled}>Projets</div>
                </Link>
                <Link to='/contact'>
                    <div className={toggled}>Contact</div>
                </Link>
                <Link to='/lab'>
                    <div className={toggled}>Laboratory</div>
                </Link>
            </div>
            <div class="three col">
                <div onClick={() => {toggleList() ; toggleNav()} } className={ham} id="hamburger-3">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
            </div>
            
            
        </div> 
        
    </div>
  )
}

export default Nav