import React from 'react'
import { useState } from 'react';
import {BrowserRouter as Router , Switch , Outlet, Route , Routes, HashRouter} from 'react-router-dom'; 
import './back.css'

function Background() {
  return (
    <div id="container">
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div>
  <div class="particlet"></div> 
</div>
  )
}

export default Background