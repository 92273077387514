import React from 'react'; 
import {useState , useEffect,  useRef} from 'react'; 
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Voice() { 

  

    const [message, setMessage] = useState(''); 
    const commands = [
      {
        command: 'reset',
        callback: () => resetTranscript()
      },
      {
        command: 'shut up',
        callback: () => setMessage('I wasn\'t talking.')
      },
      {
        command: 'Hello',
        callback: () => setMessage('Hi there!')
      },
    ] 

    const {
      transcript,
      interimTranscript,
      finalTranscript,
      resetTranscript,
      listening,
      } = useSpeechRecognition({ commands });

    

    

    
    useEffect(() => {
      if (finalTranscript !== '') {
        console.log('Got final result:', finalTranscript);
      }
    }, [interimTranscript, finalTranscript]);
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return null;
    }
   
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      console.log('Your browser does not support speech recognition software! Try Chrome desktop.');
    }
    const listenContinuously = () => {
      SpeechRecognition.startListening({
        continuous: true,
        language: 'fr-FR',
      });
    };

      
     
    


  return (
    <div>
        <h1>Voice Recognition Notepad</h1>
        <div className='words' >
        <div>
       <span>
         listening:
         {' '}
         {listening ? 'on' : 'off'}
       </span>
       <div>
         <button type="button" onClick={resetTranscript}>Reset</button>
         <button type="button" onClick={listenContinuously}>Listen</button>
         <button type="button" onClick={SpeechRecognition.stopListening}>Stop</button>
       </div>
     </div>
     <div>
     {message}
     </div>
     <div>
       <span className='transcript'>{transcript}</span>
     </div>
        </div>
    </div>
  )
}

export default Voice